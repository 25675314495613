import { COUNTRY_CODES, DEFAULT_REGEX } from './constants';

type CountryDetails = {
  iso31661: string;
  name: string;
  flag: string;
  pattern?: string;
  regex?: RegExp;
};

export type CountryType = {
  code: string;
  iso31661: string;
  name: string;
  flag: string;
  pattern: string;
  regex: RegExp;
};

export type PhoneType = {
  countryCode: string;
  input: string;
  number: string;
};

export type CountryOptionType = {
  value: string;
  label: string;
  country: CountryType;
};

export const toCountry = (countryCode: string, countryDetails: CountryDetails): CountryType => {
  const { flag, iso31661, name, pattern = '', regex = DEFAULT_REGEX } = countryDetails;
  return { code: countryCode, flag, iso31661, name, pattern, regex };
};

export const getCountries = (): CountryType[] => {
  return Object.entries(COUNTRY_CODES).map(([countryCode, countryDetails]) => toCountry(countryCode, countryDetails));
};

export const numberToPhoneType = (number: string): PhoneType => {
  const countryCodeLengths = [3, 2, 1];

  const phone = countryCodeLengths
    .map((countryCodeLength) => {
      const countryCode = getCountries().find(({ code }) => code === number.substr(1, countryCodeLength));
      if (countryCode)
        return {
          countryCode: countryCode.code,
          input: number.substr(countryCodeLength + 1),
          number,
        };

      return null;
    })
    .find((phone) => !!phone);

  if (phone) return phone;

  return { countryCode: '', input: '', number: '' };
};

export const toCountryOption = (country: CountryType): CountryOptionType => ({
  value: country.code,
  label: `${country.flag} (+${country.code}) ${country.name}`,
  country,
});

export const phoneCountriesOptions = getCountries().map(toCountryOption);

export const getDefaultCountry = (countryCode: string) => {
  const countries = getCountries();
  return countries.find(({ code }) => code === countryCode) || countries[0];
};

export const phoneTypeToNumber = ({ countryCode, number }: { countryCode: string; number: string }) => {
  if (!countryCode || !number) return '';
  // remove special characters
  const cleanNumber = number.replace(/\D/g, '');
  return `+${countryCode}${cleanNumber}`;
};

export const getPhoneCodeByIso = (country: string) => {
  const countries = getCountries();
  return countries.find(({ iso31661 }) => iso31661 === country)?.code || countries[0].code;
};
