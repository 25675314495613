import { phoneNumberRepo } from '_core/repos/phone-number.repo';

import { JwtParser } from './jwt/jwt-parser';

const CALLBACK_TIMEOUT = 2000;

export const execWithTimeout = (
  callback: (resolve: (value?: unknown) => void, reject: (reason?: string) => void) => void,
) => {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject('Execution took too much time');
    }, CALLBACK_TIMEOUT);

    callback(
      // Either the tracker passes and it resolves naturally
      (value) => {
        clearTimeout(timer);
        resolve(value);
      },
      // Or the timeout is reached and we force it to stop
      (error) => {
        clearTimeout(timer);
        reject(error);
      },
    );
  });
};

export type JwtToken = string;
export function isJwtTokenString(value: unknown): value is JwtToken {
  return JwtParser.isTokenSyntaxValid(value as unknown as string);
}

export const isPhoneNumber = (valueToValidate: string) => {
  if (!valueToValidate) return false;
  return phoneNumberRepo.isValidPhoneNumber(valueToValidate);
};

export const isPathMatchingMiddleware = (path: string, pattern: string): boolean => {
  const regex = new RegExp(`^${pattern}$`);
  return regex.test(path);
};
