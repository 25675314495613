/* eslint-disable @typescript-eslint/naming-convention */

export const DEFAULT_REGEX = /^\+\d{10}$/;

/* eslint-disable sonarjs/no-duplicate-string */
export const COUNTRY_CODES = {
  '1': {
    iso31661: 'us',
    name: 'United States / Canada',
    flag: '🇺🇸 / 🇨🇦',
    pattern: '555 123 45 67',
    regex: /^\+1\d{10}$/,
  },
  '7': {
    iso31661: 'ru',
    name: 'Russia',
    flag: '🇷🇺',
    pattern: '912 345 67 89',
  },
  '20': {
    iso31661: 'eg',
    name: 'Egypt',
    flag: '🇪🇬',
    pattern: '10 12 34 56 78',
  },
  '27': {
    iso31661: 'za',
    name: 'South Africa',
    flag: '🇿🇦',
    pattern: '712 345 678',
  },
  '30': {
    iso31661: 'gr',
    name: 'Greece',
    flag: '🇬🇷',
    pattern: '699 123 45 67',
  },
  '31': {
    iso31661: 'nl',
    name: 'Netherlands',
    flag: '🇳🇱',
    pattern: '6 12 34 56 78',
  },
  '32': {
    iso31661: 'be',
    name: 'Belgium',
    flag: '🇧🇪',
    pattern: '477 12 34 56',
    regex: /^\+32(0)?([1-9]\d{1,2})(\d{6})$/,
  },
  '33': {
    iso31661: 'fr',
    name: 'France',
    flag: '🇫🇷',
    pattern: '6 12 34 56 78',
  },
  '34': {
    iso31661: 'es',
    name: 'Spain',
    flag: '🇪🇸',
    pattern: '6 12 34 56 78',
  },
  '36': {
    iso31661: 'hu',
    name: 'Hungary',
    flag: '🇭🇺',
    pattern: '70 123 45 67',
  },
  '39': {
    iso31661: 'it',
    name: 'Italy',
    flag: '🇮🇹',
    pattern: '3123 456789',
  },
  '40': {
    iso31661: 'ro',
    name: 'Romania',
    flag: '🇷🇴',
    pattern: '712 345 678',
  },
  '41': {
    iso31661: 'ch',
    name: 'Switzerland',
    flag: '🇨🇭',
    pattern: '777 123 456',
  },
  '43': {
    iso31661: 'at',
    name: 'Austria',
    flag: '🇦🇹',
    pattern: '660 123 45 67',
  },
  '44': {
    iso31661: 'gb',
    name: 'United Kingdom',
    flag: '🇬🇧',
    pattern: '7712 34 56 78',
  },
  '45': {
    iso31661: 'dk',
    name: 'Denmark',
    flag: '🇩🇰',
    pattern: '50 12 34 56',
  },
  '46': {
    iso31661: 'se',
    name: 'Sweden',
    flag: '🇸🇪',
    pattern: '73 12 345',
  },
  '47': {
    iso31661: 'no',
    name: 'Norway',
    flag: '🇳🇴',
    pattern: '9 123 45 67',
  },
  '48': {
    iso31661: 'pl',
    name: 'Poland',
    flag: '🇵🇱',
    pattern: '888 123 456',
  },
  '49': {
    iso31661: 'de',
    name: 'Germany',
    flag: '🇩🇪',
    pattern: '177 123 45 67',
  },
  '51': {
    iso31661: 'pe',
    name: 'Peru',
    flag: '🇵🇪',
    pattern: '9 12 34 56 78',
  },
  '52': {
    iso31661: 'mx',
    name: 'Mexico',
    flag: '🇲🇽',
    pattern: '12 34 56 78 90',
  },
  '53': {
    iso31661: 'cu',
    name: 'Cuba',
    flag: '🇨🇺',
    pattern: '512 345 678',
  },
  '54': {
    iso31661: 'ar',
    name: 'Argentina',
    flag: '🇦🇷',
    pattern: '9 11 1234 5678',
  },
  '55': {
    iso31661: 'br',
    name: 'Brazil',
    flag: '🇧🇷',
    pattern: '999 123 456',
  },
  '56': {
    iso31661: 'cl',
    name: 'Chile',
    flag: '🇨🇱',
    pattern: '9 1234 5678',
  },
  '57': {
    iso31661: 'co',
    name: 'Colombia',
    flag: '🇨🇴',
    pattern: '312 345 67 89',
  },
  '58': {
    iso31661: 've',
    name: 'Venezuela',
    flag: '🇻🇪',
    pattern: '412 34 56',
  },
  '60': {
    iso31661: 'my',
    name: 'Malaysia',
    flag: '🇲🇾',
    pattern: '12 34 56 78',
  },
  '61': {
    iso31661: 'au',
    name: 'Australia',
    flag: '🇦🇺',
    pattern: '4 12 34 56 78',
  },
  '62': {
    iso31661: 'id',
    name: 'Indonesia',
    flag: '🇮🇩',
    pattern: '812 345 67 89',
  },
  '63': {
    iso31661: 'ph',
    name: 'Philippines',
    flag: '🇵🇭',
    pattern: '912 345 67 89',
  },
  '64': {
    iso31661: 'nz',
    name: 'New Zealand',
    flag: '🇳🇿',
    pattern: '22 123 45 67',
  },
  '65': {
    iso31661: 'sg',
    name: 'Singapore',
    flag: '🇸🇬',
    pattern: '8 12 345 67',
  },
  '66': {
    iso31661: 'th',
    name: 'Thailand',
    flag: '🇹🇭',
    pattern: '8 12 34 56 78',
  },
  '77': {
    iso31661: 'kz',
    name: 'Kazakhstan',
    flag: '🇰🇿',
    pattern: '777 123 45 67',
  },
  '81': {
    iso31661: 'jp',
    name: 'Japan',
    flag: '🇯🇵',
    pattern: '90 12 34 56 78',
  },
  '82': {
    iso31661: 'kr',
    name: 'South Korea',
    flag: '🇰🇷',
    pattern: '11 12 34 56 78',
  },
  '84': {
    iso31661: 'vn',
    name: 'Vietnam',
    flag: '🇻🇳',
    pattern: '9 12 34 56 78',
  },
  '86': {
    iso31661: 'cn',
    name: 'China',
    flag: '🇨🇳',
    pattern: '133 12 34 56 78',
  },
  '90': {
    iso31661: 'tr',
    name: 'Turkey',
    flag: '🇹🇷',
    pattern: '55 123 45 67 89',
  },
  '91': {
    iso31661: 'in',
    name: 'India',
    flag: '🇮🇳',
    pattern: '999 123 45 67',
  },
  '92': {
    iso31661: 'pk',
    name: 'Pakistan',
    flag: '🇵🇰',
    pattern: '333 123 45 67',
  },
  '93': {
    iso31661: 'af',
    name: 'Afghanistan',
    flag: '🇦🇫',
    pattern: '77 12 34 56',
  },
  '94': {
    iso31661: 'lk',
    name: 'Sri Lanka',
    flag: '🇱🇰',
    pattern: '77 123 45',
  },
  '95': {
    iso31661: 'mm',
    name: 'Myanmar',
    flag: '🇲🇲',
    pattern: '977 123 45 67',
  },
  '98': {
    iso31661: 'ir',
    name: 'Iran',
    flag: '🇮🇷',
    pattern: '933 12 34 56',
  },
  '212': {
    iso31661: 'ma',
    name: 'Morocco',
    flag: '🇲🇦',
    pattern: '66 12 34 56',
  },
  '213': {
    iso31661: 'dz',
    name: 'Algeria',
    flag: '🇩🇿',
    pattern: '7 12 34 56 78',
  },
  '216': {
    iso31661: 'tn',
    name: 'Tunisia',
    flag: '🇹🇳',
    pattern: '9 12 34 56 78',
  },
  '218': {
    iso31661: 'ly',
    name: 'Libya',
    flag: '🇱🇾',
    pattern: '91 23 45 67 89',
  },
  '220': {
    iso31661: 'gm',
    name: 'Gambia',
    flag: '🇬🇲',
    pattern: '99 12 34 56 78',
  },
  '221': {
    iso31661: 'sn',
    name: 'Senegal',
    flag: '🇸🇳',
    pattern: '77 12 34 56 78',
  },
  '222': {
    iso31661: 'mr',
    name: 'Mauritania',
    flag: '🇲🇷',
    pattern: '6 12 34 56 78',
  },
  '223': {
    iso31661: 'ml',
    name: 'Mali',
    flag: '🇲🇱',
    pattern: '7 123 45 67',
  },
  '224': {
    iso31661: 'gn',
    name: 'Guinea',
    flag: '🇬🇳',
    pattern: '6 12 34 56',
  },
  '225': {
    iso31661: 'ci',
    name: 'Ivory Coast',
    flag: '🇨🇮',
    pattern: '66 12 34 56',
  },
  '226': {
    iso31661: 'bf',
    name: 'Burkina Faso',
    flag: '🇧🇫',
    pattern: '77 12 34 56',
  },
  '227': {
    iso31661: 'ne',
    name: 'Niger',
    flag: '🇳🇪',
    pattern: '9 123 45 67',
  },
  '228': {
    iso31661: 'tg',
    name: 'Togo',
    flag: '🇹🇬',
    pattern: '99 12 34 56',
  },
  '229': {
    iso31661: 'bj',
    name: 'Benin',
    flag: '🇧🇯',
  },
  '230': {
    iso31661: 'mu',
    name: 'Mauritius',
    flag: '🇲🇺',
  },
  '231': {
    iso31661: 'lr',
    name: 'Liberia',
    flag: '🇱🇷',
  },
  '232': {
    iso31661: 'sl',
    name: 'Sierra Leone',
    flag: '🇸🇱',
  },
  '233': {
    iso31661: 'gh',
    name: 'Ghana',
    flag: '🇬🇭',
  },
  '234': {
    iso31661: 'ng',
    name: 'Nigeria',
    flag: '🇳🇬',
  },
  '235': {
    iso31661: 'td',
    name: 'Chad',
    flag: '🇹🇩',
  },
  '236': {
    iso31661: 'cf',
    name: 'Central African Republic',
    flag: '🇨🇫',
  },
  '237': {
    iso31661: 'cm',
    name: 'Cameroon',
    flag: '🇨🇲',
  },
  '238': {
    iso31661: 'cv',
    name: 'Cape Verde',
    flag: '🇨🇻',
  },
  '239': {
    iso31661: 'st',
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
  },
  '240': {
    iso31661: 'gq',
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
  },
  '241': {
    iso31661: 'ga',
    name: 'Gabon',
    flag: '🇬🇦',
  },
  '242': {
    iso31661: 'cg',
    name: 'Republic of the Congo',
    flag: '🇨🇬',
  },
  '243': {
    iso31661: 'cd',
    name: 'Democratic Republic of the Congo',
    flag: '🇨🇩',
  },
  '244': {
    iso31661: 'ao',
    name: 'Angola',
    flag: '🇦🇴',
  },
  '245': {
    iso31661: 'gw',
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
  },
  '246': {
    iso31661: 'io',
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
  },
  '248': {
    iso31661: 'sc',
    name: 'Seychelles',
    flag: '🇸🇨',
  },
  '249': {
    iso31661: 'sd',
    name: 'Sudan',
    flag: '🇸🇩',
  },
  '250': {
    iso31661: 'rw',
    name: 'Rwanda',
    flag: '🇷🇼',
  },
  '251': {
    iso31661: 'et',
    name: 'Ethiopia',
    flag: '🇪🇹',
  },
  '252': {
    iso31661: 'so',
    name: 'Somalia',
    flag: '🇸🇴',
  },
  '253': {
    iso31661: 'dj',
    name: 'Djibouti',
    flag: '🇩🇯',
  },
  '254': {
    iso31661: 'ke',
    name: 'Kenya',
    flag: '🇰🇪',
  },
  '255': {
    iso31661: 'tz',
    name: 'Tanzania',
    flag: '🇹🇿',
  },
  '256': {
    iso31661: 'ug',
    name: 'Uganda',
    flag: '🇺🇬',
  },
  '257': {
    iso31661: 'bi',
    name: 'Burundi',
    flag: '🇧🇮',
  },
  '258': {
    iso31661: 'mz',
    name: 'Mozambique',
    flag: '🇲🇿',
  },
  '260': {
    iso31661: 'zm',
    name: 'Zambia',
    flag: '🇿🇲',
  },
  '261': {
    iso31661: 'mg',
    name: 'Madagascar',
    flag: '🇲🇬',
  },
  '262': {
    iso31661: 're',
    name: 'Reunion',
    flag: '🇷🇪',
  },
  '263': {
    iso31661: 'zw',
    name: 'Zimbabwe',
    flag: '🇿🇼',
  },
  '264': {
    iso31661: 'na',
    name: 'Namibia',
    flag: '🇳🇦',
  },
  '265': {
    iso31661: 'mw',
    name: 'Malawi',
    flag: '🇲🇼',
  },
  '266': {
    iso31661: 'ls',
    name: 'Lesotho',
    flag: '🇱🇸',
  },
  '267': {
    iso31661: 'bw',
    name: 'Botswana',
    flag: '🇧🇼',
  },
  '268': {
    iso31661: 'sz',
    name: 'Swaziland',
    flag: '🇸🇿',
  },
  '269': {
    iso31661: 'km',
    name: 'Comoros',
    flag: '🇰🇲',
  },
  '290': {
    iso31661: 'sh',
    name: 'Saint Helena',
    flag: '🇸🇭',
  },
  '291': {
    iso31661: 'er',
    name: 'Eritrea',
    flag: '🇪🇷',
  },
  '297': {
    iso31661: 'aw',
    name: 'Aruba',
    flag: '🇦🇼',
  },
  '298': {
    iso31661: 'fo',
    name: 'Faroe Islands',
    flag: '🇫🇴',
  },
  '299': {
    iso31661: 'gl',
    name: 'Greenland',
    flag: '🇬🇱',
  },
  '350': {
    iso31661: 'gi',
    name: 'Gibraltar',
    flag: '🇬🇮',
    pattern: '588 12 34 56',
  },
  '351': {
    iso31661: 'pt',
    name: 'Portugal',
    flag: '🇵🇹',
    pattern: '912 345 678',
  },
  '352': {
    iso31661: 'lu',
    name: 'Luxembourg',
    flag: '🇱🇺',
    pattern: '661 234 567',
  },
  '353': {
    iso31661: 'ie',
    name: 'Ireland',
    flag: '🇮🇪',
    pattern: '88 123 45 67',
  },
  '354': {
    iso31661: 'is',
    name: 'Iceland',
    flag: '🇮🇸',
    pattern: '8 12 34 56',
  },
  '355': {
    iso31661: 'al',
    name: 'Albania',
    flag: '🇦🇱',
    pattern: '66 12 34 56',
  },
  '356': {
    iso31661: 'mt',
    name: 'Malta',
    flag: '🇲🇹',
    pattern: '77 12 34 56',
  },
  '357': {
    iso31661: 'cy',
    name: 'Cyprus',
    flag: '🇨🇾',
    pattern: '99 12 34',
  },
  '358': {
    iso31661: 'fi',
    name: 'Finland',
    flag: '🇫🇮',
    pattern: '500 123 45 67',
  },
  '359': {
    iso31661: 'bg',
    name: 'Bulgaria',
    flag: '🇧🇬',
    pattern: '88 123 45 67',
  },
  '370': {
    iso31661: 'lt',
    name: 'Lithuania',
    flag: '🇱🇹',
    pattern: '666 123 45',
  },
  '371': {
    iso31661: 'lv',
    name: 'Latvia',
    flag: '🇱🇻',
    pattern: '222 123 45',
  },
  '372': {
    iso31661: 'ee',
    name: 'Estonia',
    flag: '🇪🇪',
    pattern: '55 12 34 56',
  },
  '373': {
    iso31661: 'md',
    name: 'Moldova',
    flag: '🇲🇩',
    pattern: '60 12 34 56',
  },
  '374': {
    iso31661: 'am',
    name: 'Armenia',
    flag: '🇦🇲',
    pattern: '55 12 34 56',
  },
  '375': {
    iso31661: 'by',
    name: 'Belarus',
    flag: '🇧🇾',
    pattern: '29 9 123 456',
  },
  '376': {
    iso31661: 'ad',
    name: 'Andorra',
    flag: '🇦🇩',
    pattern: '3 12 34 56',
  },
  '377': {
    iso31661: 'mc',
    name: 'Monaco',
    flag: '🇲🇨',
    pattern: '6 12 34 56',
  },
  '378': {
    iso31661: 'sm',
    name: 'San Marino',
    flag: '🇸🇲',
    pattern: '66 12 34 56',
  },
  '379': {
    iso31661: 'va',
    name: 'Vatican',
    flag: '🇻🇦',
  },
  '380': {
    iso31661: 'ua',
    name: 'Ukraine',
    flag: '🇺🇦',
    pattern: '99 123 45 67',
  },
  '381': {
    iso31661: 'rs',
    name: 'Serbia',
    flag: '🇷🇸',
    pattern: '66 123 45',
  },
  '382': {
    iso31661: 'me',
    name: 'Montenegro',
    flag: '🇲🇪',
    pattern: '69 12 34',
  },
  '383': {
    iso31661: 'xk',
    name: 'Kosovo',
    flag: '🇽🇰',
  },
  '385': {
    iso31661: 'hr',
    name: 'Croatia',
    flag: '🇭🇷',
    pattern: '99 12 34 56',
  },
  '386': {
    iso31661: 'si',
    name: 'Slovenia',
    flag: '🇸🇮',
    pattern: '50 12 34 56',
  },
  '387': {
    iso31661: 'ba',
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    pattern: '66 12 34 56',
  },
  '389': {
    iso31661: 'mk',
    name: 'Macedonia',
    flag: '🇲🇰',
    pattern: '77 12 34 56',
  },
  '420': {
    iso31661: 'cz',
    name: 'Czech Republic',
    flag: '🇨🇿',
    pattern: '601 234 567',
  },
  '421': {
    iso31661: 'sk',
    name: 'Slovakia',
    flag: '🇸🇰',
    pattern: '901 234 567',
  },
  '423': {
    iso31661: 'li',
    name: 'Liechtenstein',
    flag: '🇱🇮',
    pattern: '7 12 34 56',
  },
  '500': {
    iso31661: 'fk',
    name: 'Falkland Islands',
    flag: '🇫🇰',
  },
  '501': {
    iso31661: 'bz',
    name: 'Belize',
    flag: '🇧🇿',
  },
  '502': {
    iso31661: 'gt',
    name: 'Guatemala',
    flag: '🇬🇹',
  },
  '503': {
    iso31661: 'sv',
    name: 'El Salvador',
    flag: '🇸🇻',
  },
  '504': {
    iso31661: 'hn',
    name: 'Honduras',
    flag: '🇭🇳',
  },
  '505': {
    iso31661: 'ni',
    name: 'Nicaragua',
    flag: '🇳🇮',
  },
  '506': {
    iso31661: 'cr',
    name: 'Costa Rica',
    flag: '🇨🇷',
  },
  '507': {
    iso31661: 'pa',
    name: 'Panama',
    flag: '🇵🇦',
  },
  '508': {
    iso31661: 'pm',
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
  },
  '509': {
    iso31661: 'ht',
    name: 'Haiti',
    flag: '🇭🇹',
  },
  '590': {
    iso31661: 'mf',
    name: 'Saint Martin',
    flag: '🇲🇫',
  },
  '591': {
    iso31661: 'bo',
    name: 'Bolivia',
    flag: '🇧🇴',
  },
  '592': {
    iso31661: 'gy',
    name: 'Guyana',
    flag: '🇬🇾',
  },
  '593': {
    iso31661: 'ec',
    name: 'Ecuador',
    flag: '🇪🇨',
  },
  '594': {
    iso31661: 'gf',
    name: 'French Guiana',
    flag: '🇬🇫',
  },
  '595': {
    iso31661: 'py',
    name: 'Paraguay',
    flag: '🇵🇾',
  },
  '596': {
    iso31661: 'mq',
    name: 'Martinique',
    flag: '🇲🇶',
  },
  '597': {
    iso31661: 'sr',
    name: 'Suriname',
    flag: '🇸🇷',
  },
  '598': {
    iso31661: 'uy',
    name: 'Uruguay',
    flag: '🇺🇾',
  },
  '599': {
    iso31661: 'cw',
    name: 'Curacao',
    flag: '🇨🇼',
  },
  '670': {
    iso31661: 'tl',
    name: 'East Timor',
    flag: '🇹🇱',
  },
  '672': {
    iso31661: 'aq',
    name: 'Antarctica',
    flag: '🇦🇶',
  },
  '673': {
    iso31661: 'bn',
    name: 'Brunei',
    flag: '🇧🇳',
  },
  '674': {
    iso31661: 'nr',
    name: 'Nauru',
    flag: '🇳🇷',
  },
  '675': {
    iso31661: 'pg',
    name: 'Papua New Guinea',
    flag: '🇵🇬',
  },
  '676': {
    iso31661: 'to',
    name: 'Tonga',
    flag: '🇹🇴',
  },
  '677': {
    iso31661: 'sb',
    name: 'Solomon Islands',
    flag: '🇸🇧',
  },
  '678': {
    iso31661: 'vu',
    name: 'Vanuatu',
    flag: '🇻🇺',
  },
  '679': {
    iso31661: 'fj',
    name: 'Fiji',
    flag: '🇫🇯',
  },
  '680': {
    iso31661: 'pw',
    name: 'Palau',
    flag: '🇵🇼',
  },
  '681': {
    iso31661: 'wf',
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
  },
  '682': {
    iso31661: 'ck',
    name: 'Cook Islands',
    flag: '🇨🇰',
  },
  '683': {
    iso31661: 'nu',
    name: 'Niue',
    flag: '🇳🇺',
  },
  '685': {
    iso31661: 'ws',
    name: 'Samoa',
    flag: '🇼🇸',
  },
  '686': {
    iso31661: 'ki',
    name: 'Kiribati',
    flag: '🇰🇮',
  },
  '687': {
    iso31661: 'nc',
    name: 'New Caledonia',
    flag: '🇳🇨',
  },
  '688': {
    iso31661: 'tv',
    name: 'Tuvalu',
    flag: '🇹🇻',
  },
  '689': {
    iso31661: 'pf',
    name: 'French Polynesia',
    flag: '🇵🇫',
  },
  '690': {
    iso31661: 'tk',
    name: 'Tokelau',
    flag: '🇹🇰',
  },
  '691': {
    iso31661: 'fm',
    name: 'Micronesia',
    flag: '🇫🇲',
  },
  '692': {
    iso31661: 'mh',
    name: 'Marshall Islands',
    flag: '🇲🇭',
  },
  '850': {
    iso31661: 'kp',
    name: 'North Korea',
    flag: '🇰🇵',
  },
  '852': {
    iso31661: 'hk',
    name: 'Hong Kong',
    flag: '🇭🇰',
    pattern: '599 123 45',
  },
  '853': {
    iso31661: 'mo',
    name: 'Macau',
    flag: '🇲🇴',
    pattern: '6 12 34 56',
  },
  '855': {
    iso31661: 'kh',
    name: 'Cambodia',
    flag: '🇰🇭',
    pattern: '99 12 34 56',
  },
  '856': {
    iso31661: 'la',
    name: 'Laos',
    flag: '🇱🇦',
  },
  '880': {
    iso31661: 'bd',
    name: 'Bangladesh',
    flag: '🇧🇩',
  },
  '886': {
    iso31661: 'tw',
    name: 'Taiwan',
    flag: '🇹🇼',
    pattern: '9 12 34 56 78',
  },
  '960': {
    iso31661: 'mv',
    name: 'Maldives',
    flag: '🇲🇻',
  },
  '961': {
    iso31661: 'lb',
    name: 'Lebanon',
    flag: '🇱🇧',
    pattern: '70 12 34 56',
  },
  '962': {
    iso31661: 'jo',
    name: 'Jordan',
    flag: '🇯🇴',
    pattern: '77 12 34 56',
  },
  '963': {
    iso31661: 'sy',
    name: 'Syria',
    flag: '🇸🇾',
  },
  '964': {
    iso31661: 'iq',
    name: 'Iraq',
    flag: '🇮🇶',
  },
  '965': {
    iso31661: 'kw',
    name: 'Kuwait',
    flag: '🇰🇼',
    pattern: '6 12 34 56 78',
  },
  '966': {
    iso31661: 'sa',
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    pattern: '55 123 45 67',
  },
  '967': {
    iso31661: 'ye',
    name: 'Yemen',
    flag: '🇾🇪',
    pattern: '77 123 45 67',
  },
  '968': {
    iso31661: 'om',
    name: 'Oman',
    flag: '🇴🇲',
    pattern: '99 12 34 56',
  },
  '970': {
    iso31661: 'ps',
    name: 'Palestine',
    flag: '🇵🇸',
    pattern: '59 123 45 67',
  },
  '971': {
    iso31661: 'ae',
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    pattern: '55 123 45 67',
  },
  '972': {
    iso31661: 'il',
    name: 'Israel',
    flag: '🇮🇱',
    pattern: '50 123 45 67',
  },
  '973': {
    iso31661: 'bh',
    name: 'Bahrain',
    flag: '🇧🇭',
    pattern: '33 12 34 56',
  },
  '974': {
    iso31661: 'qa',
    name: 'Qatar',
    flag: '🇶🇦',
    pattern: '5512 3456',
  },
  '975': {
    iso31661: 'bt',
    name: 'Bhutan',
    flag: '🇧🇹',
  },
  '976': {
    iso31661: 'mn',
    name: 'Mongolia',
    flag: '🇲🇳',
  },
  '977': {
    iso31661: 'np',
    name: 'Nepal',
    flag: '🇳🇵',
  },
  '992': {
    iso31661: 'tj',
    name: 'Tajikistan',
    flag: '🇹🇯',
  },
  '993': {
    iso31661: 'tm',
    name: 'Turkmenistan',
    flag: '🇹🇲',
  },
  '994': {
    iso31661: 'az',
    name: 'Azerbaijan',
    flag: '🇦🇿',
  },
  '995': {
    iso31661: 'ge',
    name: 'Georgia',
    flag: '🇬🇪',
  },
  '996': {
    iso31661: 'kg',
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
  },
  '998': {
    iso31661: 'uz',
    name: 'Uzbekistan',
    flag: '🇺🇿',
  },
  '1242': {
    iso31661: 'bs',
    name: 'Bahamas',
    flag: '🇧🇸',
  },
  '1246': {
    iso31661: 'bb',
    name: 'Barbados',
    flag: '🇧🇧',
  },
  '1264': {
    iso31661: 'ai',
    name: 'Anguilla',
    flag: '🇦🇮',
  },
  '1268': {
    iso31661: 'ag',
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
  },
  '1284': {
    iso31661: 'vg',
    name: 'British Virgin Islands',
    flag: '🇻🇬',
  },
  '1340': {
    iso31661: 'vi',
    name: 'U.S. Virgin Islands',
    flag: '🇻🇮',
  },
  '1345': {
    iso31661: 'ky',
    name: 'Cayman Islands',
    flag: '🇰🇾',
  },
  '1441': {
    iso31661: 'bm',
    name: 'Bermuda',
    flag: '🇧🇲',
  },
  '1473': {
    iso31661: 'gd',
    name: 'Grenada',
    flag: '🇬🇩',
  },
  '1649': {
    iso31661: 'tc',
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
  },
  '1664': {
    iso31661: 'ms',
    name: 'Montserrat',
    flag: '🇲🇸',
  },
  '1670': {
    iso31661: 'mp',
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
  },
  '1671': {
    iso31661: 'gu',
    name: 'Guam',
    flag: '🇬🇺',
  },
  '1684': {
    iso31661: 'as',
    name: 'American Samoa',
    flag: '🇦🇸',
  },
  '1721': {
    iso31661: 'sx',
    name: 'Sint Maarten',
    flag: '🇸🇽',
  },
  '1758': {
    iso31661: 'lc',
    name: 'Saint Lucia',
    flag: '🇱🇨',
  },
  '1767': {
    iso31661: 'dm',
    name: 'Dominica',
    flag: '🇩🇲',
  },
  '1784': {
    iso31661: 'vc',
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
  },
  '1787': {
    iso31661: 'pr',
    name: 'Puerto Rico',
    flag: '🇵🇷',
  },
  '1809': {
    iso31661: 'do',
    name: 'Dominican Republic',
    flag: '🇩🇴',
  },
  '1829': {
    iso31661: 'do',
    name: 'Dominican Republic',
    flag: '🇩🇴',
  },
  '1849': {
    iso31661: 'do',
    name: 'Dominican Republic',
    flag: '🇩🇴',
  },
  '1868': {
    iso31661: 'tt',
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
  },
  '1869': {
    iso31661: 'kn',
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
  },
  '1876': {
    iso31661: 'jm',
    name: 'Jamaica',
    flag: '🇯🇲',
  },
  '1939': {
    iso31661: 'pr',
    name: 'Puerto Rico',
    flag: '🇵🇷',
  },
} as const;
