import { ApolloError } from '@sortlist-frontend/data-fetching';
import { ABOVE_INTERCOM_INDEX, useSnackbar } from '@sortlist-frontend/design-system';
import { Monitor } from '@sortlist-frontend/mlm';
import { isNumber } from '@sortlist-frontend/utils';

import { useSharedComponentsContext } from '../../utils';

const ABOVE_INTERCOM_INDEX_1 = ABOVE_INTERCOM_INDEX + 1;

export const useCaptureValidatePhoneOwnershipError = () => {
  const { showSnackbar } = useSnackbar();
  const { t } = useSharedComponentsContext();

  const captureError = (error: ApolloError, variables: any) => {
    const ignoredErrorCodes = [60410, 60212, 60203, 60220, 60605];
    const errorCode = Number(error?.message?.split(':')[0].split(']')[1]?.trim());
    const isIgnoredErrorCode = isNumber(errorCode) && !isNaN(errorCode) && ignoredErrorCodes.includes(errorCode);

    if (!isIgnoredErrorCode) {
      Monitor.captureMessage(`[useValidatePhoneOwnership] unexpected error`, {
        extra: { extra: { error, variables } },
        tags: { errorCode: isNaN(errorCode) ? undefined : errorCode },
      });
    }

    if (errorCode === 60410) {
      showSnackbar({
        message: t('base:briefing.phoneValidation.phoneNumberTemporarlyBlocked'),
        type: 'error',
        style: { zIndex: ABOVE_INTERCOM_INDEX_1 },
      });
    } else if (errorCode === 60212 || errorCode === 60203) {
      showSnackbar({
        message: t('base:briefing.phoneValidation.tooManyAttempts'),
        type: 'error',
        style: { zIndex: ABOVE_INTERCOM_INDEX_1 },
      });
    } else {
      showSnackbar({
        message: t('base:briefing.phoneValidation.thereWasAnErrorValidatingYourPhone'),
        type: 'error',
        style: { zIndex: ABOVE_INTERCOM_INDEX_1 },
      });
    }
  };

  return { captureError };
};
