import styled from '@emotion/styled';
import ReactSelect from 'react-select';

import { COLORS, RADIUSES, SHADOWS } from '../../variables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneInputSelect = styled(ReactSelect as any)`
  .react-select__control {
    border-color: ${({ errors }) => (errors ? COLORS.DANGER_500 : COLORS.SECONDARY_900)};
    border-radius: ${RADIUSES.sm};
    box-shadow: ${SHADOWS.DEPTH_2.SECONDARY_900};
    max-height: 1.75rem;
    cursor: pointer;
  }

  .react-select__option:hover {
    color: ${COLORS.PRIMARY_500};
    background-color: ${COLORS.PRIMARY_100};
    cursor: pointer;
  }

  .react-select__option--is-focused {
    color: ${COLORS.PRIMARY_500};
    background-color: ${COLORS.PRIMARY_100};
  }

  .react-select__indicators {
    padding: 0 0.5rem;
  }

  .react-select__menu {
    width: auto;
    min-width: 100%;
    overflow: hidden;
    max-height: 12rem;
    white-space: nowrap;
    border: 1px solid ${COLORS.SECONDARY_300};
    border-radius: ${RADIUSES.sm};
    box-shadow: ${SHADOWS.DEPTH_4.SECONDARY_900};
  }
  .react-select__control:hover {
    border-color: ${COLORS.PRIMARY_500};
  }
`;
