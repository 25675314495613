import { HttpException } from '@sortlist-frontend/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { DomainInfo } from '_backend/integration/domain-info';
import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';
import { captureGqlError } from '_core/error/capture-gql-error';
import { ProjectMember } from '_types/generic';

import { EditMemberResponse, EditMemberVariables } from './types';

export const cacheKey = {
  members: (encodedProjectUuid: string) => `/api/proxy/project/${encodedProjectUuid}/find-members`,
};

const urls = {
  members: (encodedProjectUuid: string) => `/api/proxy/project/${encodedProjectUuid}/find-members`,
  updateMember: (encodedProjectUuid: string) => `/api/proxy/project/${encodedProjectUuid}/update-member`,
};

export const editMemberRepo = {
  getMembers: async (encodedProjectUuid: string, config?: AxiosRequestConfig): Promise<ProjectMember[]> => {
    const returnObject = await api.get(urls.members(encodedProjectUuid), config);
    return returnObject?.data?.data?.project?.members?.nodes;
  },
  updateMember: async (variables: EditMemberVariables, config?: AxiosRequestConfig): Promise<EditMemberResponse> => {
    const { encodedProjectUuid, memberArgs, memberId } = variables || {};
    const returnObject = await api.post(urls.updateMember(encodedProjectUuid), { memberArgs, memberId }, config);
    return returnObject?.data?.data?.updateProjectMember;
  },
};

export function useMembers(encodedProjectUuid: string, domainInfo?: DomainInfo | null) {
  const baseURL = domainInfo?.getOriginUrl() ?? '';

  return useQuery({
    queryKey: [cacheKey.members(encodedProjectUuid)],
    queryFn: () => editMemberRepo.getMembers(encodedProjectUuid, { baseURL }),
  });
}

export function useUpdateMember() {
  const { domainInfo } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: EditMemberVariables) => editMemberRepo.updateMember(variables, { baseURL }),
    onSuccess: async (_data: EditMemberResponse, variables: EditMemberVariables) => {
      const { encodedProjectUuid } = variables;
      await queryClient.invalidateQueries({
        queryKey: [cacheKey.members(encodedProjectUuid)],
      });
    },
    onError: (e: HttpException) => captureGqlError(e),
  });
}
